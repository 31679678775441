import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Helmet from "react-helmet/es/Helmet"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { css } from "styled-components"
import starsDark from "../images/stars-dark.svg"

const ContactSuccess = () => (
  <Layout>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <SEO title="Message envoyé ! Merci :)" />
    <div
      css={css`
        background-image: url(${starsDark});
        display: flex;
        height: 100vh;
        flex-flow: column nowrap;
        justify-content: center;
        justify-items: center;
      `}
    >
      <Header />

      <div
        css={css`
          flex-grow: 1;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
        `}
      >
        <div
          css={css`
            background-color: ${({ theme }) => theme.serviceBackground};
            color: ${({ theme }) => theme.textColor};
            align-self: center;
            justify-content: flex-start;
            margin: 0 auto;
            width: 80%;
            padding: 1em;
            border-radius: 20px;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
            z-index: 10;
            @media only screen and (min-width: 980px) {
              width: 50%;
            }
          `}
        >
          <h1
            css={css`
              text-align: center;
            `}
          >
            <span role="img" aria-label="emoji ok">
              👍
            </span>{" "}
            Message envoyé, merci !{" "}
            <span role="img" aria-label="emoji smiling face">
              🙂
            </span>
          </h1>
          <p>Votre message m'a bien été envoyé.</p>
          <p>
            {" "}
            Je fais tout mon possible pour vous répondre à la vitesse de
            l'éclair.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  </Layout>
)

export default ContactSuccess
